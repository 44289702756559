<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>居民健康值管理</h5>
    </div>
    <div class="line"></div>
    <el-tabs v-model="active" @tab-click="handleClick" class="tabs_box">
      <el-tab-pane :label="item.name" :name="item.id" v-for="item in quota" :key="item.id"></el-tab-pane>
    </el-tabs>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span>姓名：</span>
          <el-input placeholder="请输入" v-model="searchForm.trueName" />
        </div>
        <div class="input_box">
          <span>社区：</span>
          <el-select v-model="searchForm.communityId" clearable filterable placeholder="请选择" @change="getGroupList">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </div>
        <div class="input_box">
          <span>小组：</span>
          <el-select v-model="searchForm.groupId" clearable placeholder="请选择">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </div>
        <div class="input_box" v-if="active == 2 || active == 3">
          <span style="width:70px">指标项：</span>
          <el-select v-model="searchForm.type" clearable placeholder="请选择">
            <el-option v-for="item in quotaTypeList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </div>
<!--        <div class="input_box" style="width: 240px">
          <span style="width:70px">指标值：</span>
          <el-input v-if="active == 1" placeholder="起" v-model="searchForm.startWeight" style="width: 80px;" />
          <el-input v-else-if="active == 4" placeholder="起" v-model="searchForm.startBloodGlucose"
            style="width: 80px;" />
          <el-input v-else placeholder="起" v-model="searchForm.startValue" style="width: 80px;" />
          <span>-</span>
          <el-input placeholder="起" v-model="searchForm.endWeight" style="width: 80px;" v-if="active == 1"></el-input>
          <el-input placeholder="起" v-model="searchForm.endBloodGlucose" style="width: 80px;"
            v-else-if="active == 4"></el-input>
          <el-input placeholder="起" v-model="searchForm.endValue" style="width: 80px;" v-else></el-input>
        </div>-->
        <div class="input_box" style="width: 260px">
          <span style="width:100px">指标值：</span>
          <el-input-number placeholder="0" v-model="searchForm.startWeight" style="width: 150px;" v-if="active == 1" :min="0"></el-input-number>
          <el-input-number placeholder="0" v-model="searchForm.startBloodGlucose" style="width: 150px;" v-else-if="active == 4" :min="0"></el-input-number>
          <el-input-number placeholder="0" v-model="searchForm.startValue" style="width: 150px;" v-else :min="0"></el-input-number>
          <span>-</span>
          <el-input-number placeholder="0" v-model="searchForm.endWeight" style="width: 150px;" v-if="active == 1" :min="0"></el-input-number>
          <el-input-number placeholder="0" v-model="searchForm.endBloodGlucose" style="width: 150px;" v-else-if="active == 4" :min="0"></el-input-number>
          <el-input-number placeholder="0" v-model="searchForm.endValue" style="width: 150px;" v-else :min="0"></el-input-number>
        </div>
        <div class="input_box" style="width:460px">
          <span style="width:90px">积分时间：</span>
          <el-date-picker v-model="searchForm.startDate" value-format="yyyy-MM-dd hh:mm:ss" type="datetime"
            placeholder="日期起" style="width: 200px" />
          <span>-</span>
          <el-date-picker v-model="searchForm.endDate" value-format="yyyy-MM-dd hh:mm:ss" type="datetime"
            placeholder="日期止" style="width: 200px" />
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button></div>
        <div class="input_box"><el-button type="warning" icon="el-icon-download" @click="uploadExcel">导出</el-button></div>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="trueName" width="160" label="姓名" />
        <el-table-column prop="communityName" width="160" label="社区" />
        <el-table-column prop="groupName" width="160" label="小组" />
        <el-table-column label="指标项">
          <template slot-scope="scope">
            <span v-if="active == 1">【体重】</span>
            <span v-if="active == 2">【高压】【低压】【脉搏】</span>
            <span v-if="active == 3">【总胆固醇】【甘油三脂】【高密度胆固醇】【低密度胆固醇】</span>
            <span v-if="active == 4">{{ scope.row.timespan }}【血糖】</span>
          </template>
        </el-table-column>
        <el-table-column v-if="active == 1" label="BMI值(kg/㎡)" prop="bmi"></el-table-column>
        <el-table-column label="指标值">
          <template slot-scope="scope">
            <p v-if="active == 1">【<span class="span_bxo">{{ scope.row.weight }}KG</span>】</p>
            <p v-if="active == 2">
              【<span class="span_bxo">{{ scope.row.high }}mmHg</span>】
              【<span class="span_bxo">{{ scope.row.low }}mmHg</span>】
              【<span class="span_bxo">{{ scope.row.pulse }}bpm</span>】
            </p>
            <p v-if="active == 3">
              【<span class="span_bxo">{{ scope.row.totalCholesterol }}mmol/L</span>】
              【<span class="span_bxo">{{ scope.row.triglyceride }}mmol/L</span>】
              【<span class="span_bxo">{{ scope.row.highDensityLipoprotein }}mmol/L</span>】
              【<span class="span_bxo">{{ scope.row.lowDensityLipoprotein }}mmol/L</span>】
            </p>
            <p v-if="active == 4">【<span class="span_bxo">{{ scope.row.bloodGlucose }}mmol/L</span>】</p>
          </template>
        </el-table-column>
        <el-table-column prop="measuringTime" label="时间" width="140" />
      </el-table>
    </div>

    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
  </div>
</template>

<script>
import { chinaArea } from '@/utils/china-area-data'
import api from '@/api/index'
export default {
  name: 'index',
  components: {
  },
  data() {
    return {
      options: chinaArea,
      communityList: [],
      groupList: [],
      tableData: [],
      editDialog: false,

      searchForm: {
        "communityId": '',
        "communityIds": null,
        "endDate": "",
        "endValue": '',
        "groupId": '',
        "startDate": "",
        "startValue": '',
        "trueName": "",
        "type": "",
        "startWeight": '',
        "endWeight": '',
        "startBloodGlucose": '',
        "endBloodGlucose": ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      timer: null,
      quota: [
        { id: '1', name: '体重' }, { id: '2', name: '血压' }, { id: '3', name: '血脂' }, { id: '4', name: '血糖' },
      ],
      active: '1',
      quotaTypeList: []
    }
  },
  created() {
    this.fetchUserPower()
    this.getCommityList()
  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.searchBtn()
        }
      } catch (e) {

      }
    },
    //获取社区列表
    async getCommityList() {
      let searchForm = {
        code: ''
      }
      let pageBox = {
        pageSize: 99999,
        pageNum: 1,
      }
      try {
        try {
          let res = await api.getCommunitySystemUser(searchForm, pageBox)
          if (res.code == 200) {
            this.communityList = res.rows
          }
        } catch (e) {
          this.$message.error(e.msg)
        }
      } catch (e) {

      }
    },

    async getGroupList(val) {
      this.searchForm.groupId = ''
      try {
        let res = await api.getGroupListByCompId(val)
        if (res.code == 200) {
          this.groupList = res.data
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.searchBtn()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.searchBtn()
    },

    getSrcArr(dataArr) {
      var jsonArr = dataArr
      Object.keys(jsonArr).forEach(item => {
        if (!jsonArr[item]) delete jsonArr[item]
      })
      return jsonArr
    },
    //条件查询
    searchBtn() {
      this.tableData = []
      let jsonArr = this.getSrcArr(this.searchForm)
      switch (this.active) {
        case "1":
          this.fetchWeightList(jsonArr)
          break
        case "2":
          this.fetchBloodList(jsonArr)
          break
        case "3":
          this.fetchBloodFatList(jsonArr)
          break
        case "4":
          this.fetchBloodSugerList(jsonArr)
          break
      }
    },
    async fetchWeightList(jsonArr) {
      try {
        let res = await api.getWeightList(jsonArr, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },
    async fetchBloodList(jsonArr) {
      try {
        let res = await api.getBloodList(jsonArr, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },
    async fetchBloodFatList(jsonArr) {
      try {
        let res = await api.getBloodFatList(jsonArr, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },
    async fetchBloodSugerList(jsonArr) {
      try {
        let res = await api.getBloodSugerList(jsonArr, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {

      }
    },

    //导出excel
    uploadExcel() {
      let jsonArr = this.getSrcArr(this.searchForm)
      switch (this.active) {
        case "1":
          this.exportWeightList(jsonArr)
          break
        case "2":
          this.exportBloodList(jsonArr)
          break
        case "3":
          this.exportBloodFatList(jsonArr)
          break
        case "4":
          this.exportBloodSugerList(jsonArr)
          break
      }
    },
    async exportWeightList(dataArr) {
      try {
        let res = await api.exportWeightList(dataArr)
        let name = '体重记录'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    async exportBloodList(dataArr) {
      try {
        let res = await api.exportBloodList(dataArr)
        let name = '血压记录'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    async exportBloodFatList(dataArr) {
      try {
        let res = await api.exportBloodFatList(dataArr)
        let name = '血脂记录'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    async exportBloodSugerList(dataArr) {
      try {
        let res = await api.exportBloodSugerList(dataArr)
        let name = '血糖记录'
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {

      }
    },
    closed() {
      this.editDialog = false
    },
    handleClick(e) {
      if (e.name == 2) {
        this.quotaTypeList = [{ id: 'hight', name: '高血压值' }, { id: 'low', name: '低血压值' }, { id: 'pulse', name: '脉搏' }]
      }
      if (e.name == 3) {
        this.quotaTypeList = [{ id: 'total_cholesterol', name: '总胆固醇' }, { id: 'triglyceride', name: '甘油三脂' }, { id: 'high_density_lipoprotein', name: '高密度胆固醇' }, { id: 'low_density_lipoprotein', name: '低密度胆固醇' },]
      }
      this.searchForm.startValue = ''
      this.searchForm.startWeight = ''
      this.searchForm.startBloodGlucose = ''
      this.searchForm.endValue = ''
      this.searchForm.endWeight = ''
      this.searchForm.endBloodGlucose = ''
      this.searchForm.type = ''
      this.searchBtn()
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.input_box {
  .el-input {
    width: 70%;
  }

  .el-select {
    width: 70%;
  }
}

.tabs_box {
  padding: 0 10px;
}

.tbale_box {
  height: calc(100% - 213px) !important;
}

.span_bxo {
  margin-right: 5px;
  font-size: 16px;
}
</style>
